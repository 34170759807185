@use "sass:math";

@use "scss/functions";
@use "scss/variables";

// Options
$enable-validation-icons: false;

$blue: variables.$information-500;
$purple: variables.$primary-1;
$pink: variables.$primary-1;
$red: variables.$critical-500;
$orange: variables.$warning-500;
$green: variables.$positive-500;
$teal: variables.$positive-050;
$cyan: variables.$information-050;
$light: transparent;
//$black:   variables.$foreground-on-secondary;

$primary: variables.$primary-1;
$secondary: variables.$secondary-1;
$warning: $orange;
$danger: variables.$critical-700;
$success: variables.$positive-700;
$dark: variables.$foreground-high;

$info: variables.$information-050;
$alert: variables.$warning-500;

$body-bg: variables.$background-1;
$body-color: variables.$foreground-high;

$link-color: variables.$information-500;

$disabled-color: variables.$foreground-disabled;

$border-radius: variables.$border-radius;
$border-radius-sm: variables.$border-radius-sm;
$border-radius-lg: variables.$border-radius-lg;

// Options

$enable-negative-margins: true;

// Spacing

$spacer: functions.get-rem(8px);
$spacers: (
  0: 0,
  quarter: calc($spacer / 4),
  half: calc($spacer / 2),
  semihalf: calc($spacer / 1.333),
  1: $spacer,
  1_half: $spacer * 1.5,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  11: $spacer * 12,
  12: $spacer * 12,
  13: $spacer * 13,
  14: $spacer * 14,
  15: $spacer * 15,
  16: $spacer * 16,
  17: $spacer * 17,
  18: $spacer * 18,
  19: $spacer * 19,
  20: $spacer * 20,
);

// Typography

$font-family-base: variables.$font-family-base;

$line-height-base: variables.$line-height-base;
$line-height-lg: variables.$line-height-lg;

// Buttons + Forms

$input-btn-font-size: variables.$ui-m;

$input-btn-border-width: 1px;

// Buttons

$btn-font-size: $input-btn-font-size;
$btn-line-height: $line-height-base;
$btn-padding-y: functions.get-rem(14px);
$btn-padding-x: map-get($spacers, 3);
$btn-padding-y-sm: map-get($spacers, 1);
$btn-padding-x-sm: map-get($spacers, 2);

$btn-font-size-sm: variables.$ui-s;
$btn-font-size-lg: $btn-font-size;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: variables.$font-weight-medium;
$btn-box-shadow: null;
$btn-focus-width: 2px;
$btn-focus-box-shadow: variables.$information-900;
$btn-disabled-opacity: 1;
// $btn-active-box-shadow: inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color: $primary;
$btn-link-hover-color: $btn-link-color;
$btn-link-disabled-color: variables.$foreground-disabled;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: variables.$border-radius-xxxxl;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

// $btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount: 0%;
$btn-hover-bg-tint-amount: 0%;
$btn-hover-border-shade-amount: 0%;
$btn-hover-border-tint-amount: 0%;
// $btn-active-bg-shade-amount: 20% !default;
// $btn-active-bg-tint-amount: 20% !default;
// $btn-active-border-shade-amount: 25% !default;
// $btn-active-border-tint-amount: 10% !default;

// Forms

// form text

$form-text-margin-top: map-get($spacers, 1);
$form-text-color: variables.$foreground-high;

// form label

$form-label-margin-bottom: map-get($spacers, 1);

// form-input

$input-padding: map-get($spacers, 1);
$input-font-weight: variables.$font-weight-regular;
$input-line-height: $line-height-base;

$input-padding-y-sm: functions.get-rem(10px);
$input-padding-x-sm: $input-padding;
$input-font-size-sm: variables.$ui-s;

$input-padding-y: functions.get-rem(14px);
$input-padding-x: $input-padding;
$input-font-size: variables.$ui-m;

$input-bg: variables.$surface-1;
$input-disabled-bg: variables.$element-disabled;
$input-disabled-border-color: variables.$stroke-high;
$input-disabled-color: variables.$foreground-disabled;

$input-color: variables.$foreground-high;
$input-border-color: variables.$stroke-high;
$input-border-width: $input-btn-border-width;
$input-box-shadow: none !important;

$input-border-radius: variables.$border-radius;
$input-border-radius-sm: $input-border-radius;
$input-border-radius-lg: $input-border-radius;

$input-hover-border-color: variables.$information-900;

$input-focus-bg: $input-bg;
$input-focus-border-color: variables.$primary-1;
$input-focus-width: $input-font-weight;
$input-focus-box-shadow: none !important;

$input-placeholder-color: variables.$foreground-low;

$input-height-border: $input-border-width * 2;

// form-check

$form-check-padding-y: functions.get-rem(8px);
$form-check-padding-x: map-get($spacers, 1);

$form-check-input-offset: functions.get-rem(2px); // custom
$form-check-input-width: map-get($spacers, 3) - $form-check-input-offset * 2;
$form-check-min-height: unset;
$form-check-padding-start: -($form-check-padding-x + $form-check-input-offset);
$form-check-margin-bottom: 0;
$form-check-label-color: currentColor;
$form-check-label-cursor: pointer;
// $form-check-transition: null;

// $form-check-input-active-filter: brightness(90%);

$form-check-input-bg: transparent;
$form-check-input-border: 1px solid variables.$primary-1;
$form-check-input-border-radius: $border-radius;
// $form-check-radio-border-radius: 50%;
// $form-check-input-focus-border: $input-focus-border-color;
// $form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: variables.$surface-1;
$form-check-input-checked-bg-color: currentColor;
// $form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='#{$form-check-input-checked-color}' fill-rule='evenodd' clip-rule='evenodd' d='M15.7893 6.10761C16.0405 6.37678 16.0259 6.79864 15.7568 7.04986L8.86317 13.9249C8.60704 14.164 8.20956 14.164 7.95342 13.9249L4.24324 10.1178C3.97407 9.86661 3.95952 9.44475 4.21075 9.17559C4.46197 8.90642 4.88383 8.89187 5.153 9.14309L8.4083 12.5256L14.847 6.07512C15.1162 5.8239 15.538 5.83844 15.7893 6.10761Z'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='purple  ' stroke='white' stroke-width='1.5px'/></svg>");

$form-check-input-indeterminate-color: $form-check-input-checked-color;
$form-check-input-indeterminate-bg-color: $form-check-input-checked-bg-color;
// $form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
// $form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: 1;
// $form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
// $form-check-btn-check-disabled-opacity: $btn-disabled-opacity;

$form-check-inline-margin-end: map-get($spacers, 1);

// switch

$form-switch-font-size: 18px;

$form-switch-color: #fff;
$form-switch-width: functions.get-em(56px, $form-switch-font-size);
$form-switch-height: 32px;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><rect width='24' height='24' rx='12' fill='#{$form-switch-color}' /></svg>");
$form-switch-bg-offset: 4px;
$form-switch-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out;

$form-switch-focus-color: $form-switch-color;
$form-switch-focus-bg-image: $form-switch-bg-image;

$form-switch-checked-color: $form-switch-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><rect width='24' height='24' rx='12' fill='#{$form-switch-checked-color}' /><path fill-rule='evenodd' clip-rule='evenodd' d='M19.435 7.29289C19.0445 6.90237 18.4114 6.90237 18.0208 7.29289L10.2426 15.0712L6.70711 11.5357C6.31658 11.1452 5.68342 11.1452 5.29289 11.5357C4.90237 11.9262 4.90237 12.5594 5.29289 12.9499L9.53553 17.1926C9.92606 17.5831 10.5592 17.5831 10.9497 17.1926C10.9532 17.1891 10.9566 17.1856 10.96 17.1821L19.435 8.70711C19.8256 8.31658 19.8256 7.68342 19.435 7.29289Z' fill='#{variables.$positive-900}'/></svg>");

// input-group
// select

$form-select-indicator-padding: functions.get-rem(42px);
$form-select-bg: $input-bg !default;
$form-select-bg-position: right 10px center;
$form-select-bg-size: 32px 30px; // In pixels because image dimensions
$form-select-indicator-color: variables.$foreground-high;
$form-select-indicator: url("data:image/svg+xml,<svg width='32' height='30' viewBox='0 0 32 30' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='0.5' y1='0.5' x2='0.499999' y2='29.5' stroke='#{$form-select-indicator-color}' stroke-linecap='round' /><path d='M14.1295 12.5812C13.6567 11.9194 14.1298 11 14.9432 11H26.0568C26.8702 11 27.3433 11.9194 26.8705 12.5812L21.3137 20.3608C20.9149 20.9191 20.0851 20.9191 19.6863 20.3608L14.1295 12.5812Z' fill='#{$form-select-indicator-color}' /></svg>");

$form-select-border-color: #000;
$form-select-border-radius: variables.$border-radius;

// Form validation

// $form-feedback-valid-color: $success;
$form-feedback-invalid-color: variables.$critical-500;

// Navs

$nav-link-font-size: functions.get-rem(16px);
$nav-link-padding-y: functions.get-rem(16px);
$nav-link-padding-x: functions.get-rem(32px);
$nav-link-color: variables.$foreground-high;
$nav-link-hover-color: variables.$foreground-high;
$nav-link-transition: font-weight 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out;

$nav-pills-border-radius: variables.$border-radius-lg;
$nav-pills-link-active-color: $nav-link-color;
$nav-pills-link-active-bg: variables.$additional-13-200;

// Accordion

$accordion-padding-y: 0;
$accordion-padding-x: 0;
$accordion-color: $body-color;
$accordion-bg: transparent;
$accordion-border-width: 0;
$accordion-border-color: transparent;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;

$accordion-button-color: $accordion-color;
$accordion-button-active-bg: $accordion-bg;
$accordion-button-active-color: $accordion-color;

$accordion-button-focus-border-color: null;
$accordion-button-focus-box-shadow: null;

$accordion-icon-width: 1em;
$accordion-icon-color: $accordion-button-color !default;
$accordion-icon-active-color: $accordion-button-active-color !default;
$accordion-icon-transform: rotate(-90deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25' fill='none'><path d='M14.1713 5.42914C14.6927 4.91493 15.5312 4.91774 16.0491 5.43542C16.5729 5.95902 16.5692 6.80926 16.0409 7.32829L10.774 12.5027L16.0401 17.6718C16.5688 18.1907 16.5726 19.0412 16.0487 19.5649C15.531 20.0825 14.6927 20.0854 14.1714 19.5715L8.44454 13.9266C7.65005 13.1434 7.64984 11.8617 8.44406 11.0783L14.1713 5.42914Z' fill='#{$accordion-icon-color}' /></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25' fill='none'><path d='M14.1713 5.42914C14.6927 4.91493 15.5312 4.91774 16.0491 5.43542C16.5729 5.95902 16.5692 6.80926 16.0409 7.32829L10.774 12.5027L16.0401 17.6718C16.5688 18.1907 16.5726 19.0412 16.0487 19.5649C15.531 20.0825 14.6927 20.0854 14.1714 19.5715L8.44454 13.9266C7.65005 13.1434 7.64984 11.8617 8.44406 11.0783L14.1713 5.42914Z' fill='#{$accordion-icon-active-color}' /></svg>");
//Table
$table-bg: variables.$surface-1;
// Dropdowns
$dropdown-bg: variables.$surface-1;
// Offcanvas
$offcanvas-bg-color: variables.$surface-1;
// Cards
$card-bg: variables.$surface-1;
// Spinner
$spinner-border-width-sm: 2px;
// Modal
$modal-sm: 476px;
$modal-lg: 690px;
$modal-content-bg: variables.$surface-1;
//tooltip
$tooltip-bg: variables.$surface-1;
$tooltip-color: variables.$foreground-low;

;@import "sass-embedded-legacy-load-done:521";